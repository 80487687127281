import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ImageIcon } from '@icons/wolfkit-light/image-light.svg';
import FileInput from '@shared/ui/inputs/FileInput';
import Button from '@shared/ui/buttons/Button';
import { StepContainer, Title } from '@widgets/sidebars/shared';
import { FieldLabel, StepTitleCounter, } from '../shared';
const FileInputContainer = styled.div(props => ({
    marginBottom: `${props.theme.spacing_sizes.xs * 5.25}px`,
}));
const InputsContainer = styled.div();
const UploadDocuments = ({ isCreating, goToNextStep }) => {
    const { t } = useTranslation();
    const handleBtnClick = () => {
        if (goToNextStep) {
            goToNextStep();
        }
    };
    return (_jsx(StepContainer, { title: (_jsxs(Title, { children: [_jsx(StepTitleCounter, { children: "4/4" }), t('profile.create_trader.steps.documents_upload.title', { ns: 'common' })] })), content: (_jsxs(InputsContainer, { children: [_jsxs(FileInputContainer, { children: [_jsx(FieldLabel, { children: t('profile.create_trader.steps.documents_upload.passport_photo.title', { ns: 'common' }) }), _jsx(FileInput, { innerLabelText: t('profile.create_trader.steps.documents_upload.passport_photo.inner_label', { ns: 'common' }), description: t('profile.create_trader.steps.documents_upload.passport_photo.description', { ns: 'common' }), maxSize: 800000, icon: _jsx(ImageIcon, {}), mimeType: ['image/gif', 'image/jpeg', 'image/png'], size: 'small' })] }), _jsx(FieldLabel, { children: t('profile.create_trader.steps.documents_upload.face_photo.title', { ns: 'common' }) }), _jsx(FileInput, { innerLabelText: t('profile.create_trader.steps.documents_upload.face_photo.inner_label', { ns: 'common' }), description: t('profile.create_trader.steps.documents_upload.face_photo.description', { ns: 'common' }), maxSize: 800000, icon: _jsx(ImageIcon, {}), mimeType: ['image/gif', 'image/jpeg', 'image/png'], size: 'small' })] })), buttons: (_jsx(Button, { variant: 'filled', color: 'primary', size: 'large', fullWidth: true, onClick: handleBtnClick, isLoading: isCreating, disabled: isCreating, children: t('profile.create_trader.steps.documents_upload.continue_btn_text', { ns: 'common' }) })) }));
};
export default UploadDocuments;
