import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import IconWithText from '@shared/ui/display/IconWIthText';
import Button from '@shared/ui/buttons/Button';
const IconWrapper = styled.span(props => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 'inherit',
    height: 'inherit',
    borderRadius: '50%',
    backgroundColor: props.theme.customColors.shades.primaryLight,
    color: props.theme.palette.primary.main,
    fontSize: `${props.theme.spacing_sizes.xs * 5.5}px`,
    fontWeight: 600,
    userSelect: 'none',
}));
const WarningIcon = () => _jsx(IconWrapper, { children: "?" });
const WarningContainer = styled.div(() => ({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
}));
const ButtonsContainer = styled.div(props => ({
    display: 'flex',
    flexDirection: 'column',
    gap: `${props.theme.spacing_sizes.m}px`,
}));
const Warning = ({ returnToEdit, exitFromForm }) => {
    const { t } = useTranslation();
    return (_jsxs(WarningContainer, { children: [_jsx(IconWithText, { IconElement: _jsx(WarningIcon, {}), text: t('profile.create_trader.steps.warning.text', { ns: 'common' }) }), _jsxs(ButtonsContainer, { children: [_jsx(Button, { variant: 'filled', color: 'primary', size: 'large', fullWidth: true, onClick: exitFromForm, children: t('profile.create_trader.steps.warning.approve_btn_text', { ns: 'common' }) }), _jsx(Button, { variant: 'tinted', color: 'primary', size: 'large', fullWidth: true, onClick: returnToEdit, children: t('profile.create_trader.steps.warning.decline_btn_text', { ns: 'common' }) })] })] }));
};
export default Warning;
