import * as yup from 'yup';
import { checkStringEdges, publicNameSchema } from '@utils/validation';
import { REGEXP_USERNAME } from '@app/constants/validation';
const introductionValidationSchema = (translateFn) => yup.object().shape({
    displayName: yup.lazy(publicNameSchema(translateFn, true)),
    location: yup.string().min(1, translateFn('profile.messages.location_empty')),
    username: yup.string().required(translateFn('profile.messages.nickname_empty')).matches(REGEXP_USERNAME, translateFn('profile.messages.nickname_only_latin_and_numbers')).max(30, ' ')
        .test('special-chars-in-edge', translateFn('profile.messages.nickname_special_chars_edges'), checkStringEdges),
    initialUsername: yup.string(),
});
const personalDetailsValidationSchema = yup.object().shape({});
export { introductionValidationSchema, personalDetailsValidationSchema, };
